import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import { graphql } from "gatsby";
import React from "react";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import UniqueContent from "../components/common/UniqueContent/UniqueContent";
import reactStringReplace from 'react-string-replace';
import css from "@styles-pages/magento.module.scss";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getPage',
    title: 'seoPageTitle',
    description: 'seoPageMetaDescription',
    image: 'coverImage',
    structureddata: "stucturedData"
};

const Page = (props) => {
    const { data } = props;

    const {
        pageTitle,
        pageContent,
        stucturedData,
        pageReference,
        pageIntroTitle,
        pageIntroPlain,
        ContactFormPage
    } = data.pim.getPage;

    const search = 'src="/SEPWA/'
    const replace = `src="${process.env.PIMCORE_BASE_URL}/SEPWA/`;
    const replaceResult = reactStringReplace(pageContent, search, (match, i) => (replace))

    return (

        <ContentWrapper isWide={true}>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData} />
            <Header image size="oneLiner">
                <ContentWrapper
                    isWide={false}
                    className={css.contentWrapper}
                    vertical="center"
                >
                    <ContentWrapper
                        className={css.introduction}
                        isWide={true}
                        maxWidth="maxWidthSmall"
                    >
                        <Heading
                            isSemantic={true}
                            variant="h1"
                            color="white"
                        >
                            {pageIntroTitle}
                        </Heading>
                        <HighlightedText color="white" borderColor="orange">
                            {pageIntroPlain}
                        </HighlightedText>
                    </ContentWrapper>
                </ContentWrapper>
            </Header>
            {/* Changes SEPWA */}
            {ContactFormPage ?
                <ContentWrapper
                    paddedTop
                    paddedBottom
                >
                    <div className={css.download}>
                        <div className={css.topContainer}>
                            <div className={css.left}>
                                <div dangerouslySetInnerHTML={{ __html: replaceResult.join("") }}></div>
                            </div>
                            <div className={css.right}>
                                <div dangerouslySetInnerHTML={{ __html: ContactFormPage }}>
                                </div>
                            </div>
                        </div>
                        <div className={css.bottomContainer}>
                            {/* <div dangerouslySetInnerHTML={{ __html: pageContent2 }}>
                            </div> */}
                        </div>
                    </div>
                    <UniqueContent pageReference={pageReference} />
                </ContentWrapper>
                :
                <ContentWrapper
                    paddedTop={true}
                    paddedBottom={true}
                >
                    <div dangerouslySetInnerHTML={{ __html: replaceResult.join("") }}></div>
                    <UniqueContent pageReference={pageReference} />
                </ContentWrapper>
            }
        </ContentWrapper>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getPage(id: $id, defaultLanguage: $hrefLang) {
                pageTitle
                pageContent
                seoPageTitle
                seoPageMetaDescription
                stucturedData
                pageReference
                pageIntroTitle
                pageIntroPlain
                ContactFormPage
                coverImage {
                    id
                    fullpath
                }
            }
        }
    }
`;
