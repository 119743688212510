import { store } from '@redux/provider';
const kebabCase = require("../util/page-builder").kebabCase;


// Using import { LocalizedLink as Link } from "gatsby-theme-i18n"
// Will take care of the locale prefix

export const urlBuilder = {
    buildStaticUrl: function (path) {
        return `/${kebabCase(path)}`;
    },
    buildDynamicUrl: function (id) {
        const { pages } = store.getState();

        if (pages === undefined || pages[id] === undefined) return '/page-does-not-exist';

        const path = pages[id].split('/');

        if (path.length > 1) {
            path[path.length - 1] = kebabCase(path[path.length - 1]);
        }

        const formattedPath = `${path.join('/')}/`;

        return formattedPath;
    }
}

export default urlBuilder;