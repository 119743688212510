import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import React from "react";
import css from "./PartnerGrid.module.scss";
import classNames from "classnames";

import { LocalizedLink as Link } from "gatsby-theme-i18n";
import urlBuilder from "@util/url-builder";
const PartnerGrid = (props) => {

    const { partners } = props;
    return (

        <div className={classNames(css.partnerContainer)}>
            {partners.map((item, index) =>
                <Link
                    to={urlBuilder.buildDynamicUrl(
                        item.id
                    )}
                    className={classNames(css.partnerItem)}
                    key={index}
                >
                    {item.partnerTier &&
                        <div className={classNames(css.partnerLabel)}>{item.partnerTier}</div>
                    }

                    <div className={classNames(css.partnerItemLogo)}>

                        <img className={classNames(css.partnerLogo)}
                            src={generateThumbnailUrl(
                                item.techLogo,
                                "logo_thumbnail"
                            )}
                            alt={`${item.techName} logo`} />

                    </div>
                    <div className={classNames(css.partnerItemText)}>
                        {item.partnerIntro}
                    </div>
                </Link>
            )
            }

        </div>
    );

}

export default PartnerGrid;
