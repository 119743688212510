import React, { Component } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import css from "./Dropdown.module.scss";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsDropdownOpen: false,
        };
    }

    close = () => {
        this.setState({
            IsDropdownOpen: false,
        });
    };

    componentDidUpdate() {
        const { IsDropdownOpen } = this.state;
        if (IsDropdownOpen) {
            window.addEventListener("click", this.close);
        } else {
            window.removeEventListener("click", this.close);
        }
    }

    toggleList = () => {
        this.setState((prevState) => ({
            IsDropdownOpen: !prevState.IsDropdownOpen,
        }));
    };

    closeList = () => {
        this.setState({
            IsDropdownOpen: false,
        });
    };

    openList = () => {
        this.setState({
            IsDropdownOpen: true,
        });
    };
    
    render() {
        const { IsDropdownOpen } = this.state;
        return (
            <div onMouseLeave={this.closeList}>
                <Link onMouseOver={this.openList} to={this.props.headerUrl}>
                    {this.props.headerTitle}
                    <img
                        className={`${css.img}`}
                        alt="arrowLogo"
                        src={
                            this.props.isStickyNavigationOpen
                                ? typeof document !== undefined &&
                                    (IsDropdownOpen ||
                                    document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                        ? "/assets/icons/down-arrow-blue.svg"
                                        : "/assets/icons/down-arrow-black.svg")
                                : "/assets/icons/down-arrow.svg"
                        }
                    />
                </Link>
                {IsDropdownOpen && (
                    <div className={`${css.elements}`}>
                        <ul>
                            {this.props.dropdownInfo.map((item) => (
                                <li key={item.id} className={`${css.element}`}>
                                    <Link
                                        to={item.Link}
                                        onClick={() => {
                                            this.closeList();
                                            this.props.setIsMobileNavigationOpen(
                                                false
                                            );
                                        }}
                                    >
                                        <span>{item.Name}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
