import React from 'react';
import ReactHelmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { useLocation } from '@reach/router';
import { parseServerSideMeta } from '../../../util/dispatch';

const Helmet = (props) => {
    const { locale, title, description, location, image, url, structureddata } = props;


    return <ReactHelmet
        htmlAttributes={{
            lang: locale
        }}
        title={title}
        titleTemplate={`%s - Studio Emma`}
        meta={[
            {
                name: 'description',
                content: description
            },
            {
                property: 'og:site_name',
                content: 'Studio Emma'
            },
            {
                property: 'og:title',
                content: title
            },
            {
                property: 'og:description',
                content: description
            },
            {
                property: 'og:url',
                content: location
            },
            {
                property: 'og:locale',
                content: locale === 'nl' ? 'nl_BE' : 'en_US' // dirty fix before live
            },
            {
                property: 'og:image',
                content: image
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                name: 'twitter:card',
                content: 'summary'
            },
            {
                name: 'twitter:creator',
                content: 'Studio Emma'
            },
            {
                name: 'twitter:title',
                content: title
            },
            {
                name: 'twitter:description',
                content: description
            }
        ]}
    >
        <link href={url} rel="canonical" />
        <link href={url} hreflang="x-default" rel="alternate" />
        <link href={url} hreflang={locale} rel="alternate" />
        <script type="application/ld+json">{structureddata}</script>
    </ReactHelmet>

}

export const ClientSideMetaTags = (props) => {
    const { pageContext } = props;
    const meta = useSelector((state) => state.meta);

    const title = meta.title || 'Studio Emma';
    const description = meta.description || 'E-commerce expertise!';
    const image = (typeof meta.image === 'object') ? generateThumbnailUrl(meta.image, 'general_thumbnail') : '/assets/fallback.jpg';
    const structureddata = meta.structureddata || null;

    if (title && typeof window !== 'undefined') window.document.title = title;

    const location = useLocation().href;
    const searchParameters = useLocation().search;
    const newUrl = (location) ? location.replace(searchParameters, '') : '/url-not-found';

    return (
        (typeof window !== 'undefined') && <Helmet
            structureddata={structureddata}
            title={title}
            description={description}
            image={image}
            locale={pageContext.locale}
            url={newUrl}
            location={location}
        />
    );
}

export const ServerSideMetaTags = (props) => {
    const { pim, metadata, structureddata } = props;
    const meta = parseServerSideMeta(pim, metadata);

    const title = meta.title || 'Studio Emma';
    const description = meta.description || 'E-commerce expertise!';
    const image = (typeof meta.image === 'object') ? generateThumbnailUrl(meta.image, 'general_thumbnail') : '/assets/fallback.jpg';


    const location = process.env.SITE_URL + useLocation().pathname; // dirty fix before live
    const searchParameters = useLocation().search;
    const newUrl = (location) ? location.replace(searchParameters, '') : '/url-not-found';
    const locale = newUrl.includes('/en/') ? 'en' : 'nl'; // dirty fix before live
    return (
        (typeof window === 'undefined') && <Helmet
            structureddata={structureddata}
            title={title}
            description={description}
            image={image}
            locale={locale}
            url={newUrl}
            location={location}
        />
    );

}


export default ClientSideMetaTags;
